import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { sa } from '@angular/core/src/render3';
import { MatCheckbox, MatRadioButton } from '@angular/material';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-sistema-venoso-profundo',
  templateUrl: './sistema-venoso-profundo.component.html',
  styleUrls: ['./sistema-venoso-profundo.component.css']
})
export class SistemaVenosoProfundoComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() camposObrigatoriosPreenchidos = new EventEmitter();

  /* Direito */
  @ViewChild('rdNaoCitar') rdNaoCitar: MatRadioButton;
  @ViewChild('rdTrombose') rdTrombose: MatRadioButton;
  @ViewChild('rdRecanalizacao') rdRecanalizacao: MatRadioButton;
  @ViewChild('rdTromboseCronica') rdTromboseCronica: MatRadioButton;
  @ViewChild('rdRefluxo') rdRefluxo: MatRadioButton;
  @ViewChild('rdAusenciaFasicidade') rdAusenciaFasicidade: MatRadioButton;
  @ViewChild('rdSegmentosNormais') rdSegmentosNormais: MatRadioButton;

  @ViewChild('rdHiperecogenico') rdHiperecogenico: MatRadioButton;
  @ViewChild('rdHipoecogenico') rdHipoecogenico: MatRadioButton;

  @ViewChild('ckEspessamentoParietal') ckEspessamentoParietal: MatCheckbox;
  @ViewChild('ckTrabeculacoes') ckTrabeculacoes: MatCheckbox;
  @ViewChild('ckRefluxo') ckRefluxo: MatCheckbox;
  @ViewChild('ckPerdaRelacaoAnatomica') ckPerdaRelacaoAnatomica: MatCheckbox;

  @ViewChild('ckFemoralComum') ckFemoralComum: MatCheckbox;
  @ViewChild('ckFemoralProfunda') ckFemoralProfunda: MatCheckbox;
  @ViewChild('ckFemoralSuperficial') ckFemoralSuperficial: MatCheckbox;
  @ViewChild('ckPoplitea') ckPoplitea: MatCheckbox;
  @ViewChild('ckTibialAnterior') ckTibialAnterior: MatCheckbox;
  @ViewChild('ckTibialPosterior') ckTibialPosterior: MatCheckbox;
  @ViewChild('ckFibular') ckFibular: MatCheckbox;
  @ViewChild('ckSoleares') ckSoleares: MatCheckbox;
  @ViewChild('ckIntergemelar') ckIntergemelar: MatCheckbox;

  @ViewChild('ckTercoSuperior') ckTercoSuperior: MatCheckbox;
  @ViewChild('ckTercoMedio') ckTercoMedio: MatCheckbox;
  @ViewChild('ckTercoDistal') ckTercoDistal: MatCheckbox;
  /* Esquerdo */
  @ViewChild('rdNaoCitar2') rdNaoCitar2: MatRadioButton;
  @ViewChild('rdTrombose2') rdTrombose2: MatRadioButton;
  @ViewChild('rdRecanalizacao2') rdRecanalizacao2: MatRadioButton;
  @ViewChild('rdTromboseCronica2') rdTromboseCronica2: MatRadioButton;
  @ViewChild('rdRefluxo2') rdRefluxo2: MatRadioButton;
  @ViewChild('rdAusenciaFasicidade2') rdAusenciaFasicidade2: MatRadioButton;
  @ViewChild('rdSegmentosNormais2') rdSegmentosNormais2: MatRadioButton;

  @ViewChild('rdHiperecogenico2') rdHiperecogenico2: MatRadioButton;
  @ViewChild('rdHipoecogenico2') rdHipoecogenico2: MatRadioButton;

  @ViewChild('ckEspessamentoParietal2') ckEspessamentoParietal2: MatCheckbox;
  @ViewChild('ckTrabeculacoes2') ckTrabeculacoes2: MatCheckbox;
  @ViewChild('ckRefluxo2') ckRefluxo2: MatCheckbox;
  @ViewChild('ckPerdaRelacaoAnatomica2') ckPerdaRelacaoAnatomica2: MatCheckbox;

  @ViewChild('ckFemoralComum2') ckFemoralComum2: MatCheckbox;
  @ViewChild('ckFemoralProfunda2') ckFemoralProfunda2: MatCheckbox;
  @ViewChild('ckFemoralSuperficial2') ckFemoralSuperficial2: MatCheckbox;
  @ViewChild('ckPoplitea2') ckPoplitea2: MatCheckbox;
  @ViewChild('ckTibialAnterior2') ckTibialAnterior2: MatCheckbox;
  @ViewChild('ckTibialPosterior2') ckTibialPosterior2: MatCheckbox;
  @ViewChild('ckFibular2') ckFibular2: MatCheckbox;
  @ViewChild('ckSoleares2') ckSoleares2: MatCheckbox;
  @ViewChild('ckIntergemelar2') ckIntergemelar2: MatCheckbox;

  @ViewChild('ckTercoSuperior2') ckTercoSuperior2: MatCheckbox;
  @ViewChild('ckTercoMedio2') ckTercoMedio2: MatCheckbox;
  @ViewChild('ckTercoDistal2') ckTercoDistal2: MatCheckbox;

  lateralidadeSelected: string = 'direito';
  showAndHideTerms: any;
  showAndHideTerms2: any;

  objetoVaso: any;
  objetoVaso2: any;
  objetoSegmento: any;
  objetoSegmento2: any;
  objetoAchados: any;
  objetoAchados2: any;

  constructor(private _common: CommonService) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahTrombose: false,
      sahRecanalizacao: false,
      sahTromboseCronica: false,
      sahRefluxo: false,
      sahAusenciaFasicidade: false,
      sahSegmentosNormais: false,
      sahListaSegmentos: false,
    }
    this.showAndHideTerms2 = {
      sahTrombose2: false,
      sahRecanalizacao2: false,
      sahTromboseCronica2: false,
      sahRefluxo2: false,
      sahAusenciaFasicidade2: false,
      sahSegmentosNormais2: false,
      sahListaSegmentos2: false,
    };

    this._common.lateralidade.subscribe((lateralidade: any) => {
      this.lateralidadeSelected = lateralidade;
    });
  }

  clickNaoCitar(){
    this.showAndHideTerms.sahTrombose = false;
    this.showAndHideTerms.sahRecanalizacao = false;
    this.showAndHideTerms.sahTromboseCronica = false;
    this.showAndHideTerms.sahRefluxo = false;
    this.showAndHideTerms.sahAusenciaFasicidade = false;
    this.showAndHideTerms.sahSegmentosNormais = false;
    this.catchIDs();
  }

  clickNaoCitar2(){
    this.showAndHideTerms2.sahTrombose2 = false;
    this.showAndHideTerms2.sahRecanalizacao2 = false;
    this.showAndHideTerms2.sahTromboseCronica2 = false;
    this.showAndHideTerms2.sahRefluxo2 = false;
    this.showAndHideTerms2.sahAusenciaFasicidade2 = false;
    this.showAndHideTerms2.sahSegmentosNormais2 = false;
    this.catchIDs();
  }
  clickTrombose(){
    this.showAndHideTerms.sahTrombose = true;
    this.showAndHideTerms.sahRecanalizacao = false;
    this.showAndHideTerms.sahTromboseCronica = false;
    this.showAndHideTerms.sahRefluxo = false;
    this.showAndHideTerms.sahAusenciaFasicidade = false;
    this.showAndHideTerms.sahSegmentosNormais = false;
    this.catchIDs();
  }

  clickTrombose2(){
    this.showAndHideTerms2.sahTrombose2 = true;
    this.showAndHideTerms2.sahRecanalizacao2 = false;
    this.showAndHideTerms2.sahTromboseCronica2 = false;
    this.showAndHideTerms2.sahRefluxo2 = false;
    this.showAndHideTerms2.sahAusenciaFasicidade2 = false;
    this.showAndHideTerms2.sahSegmentosNormais2 = false;
    this.catchIDs();
  }
  clickRecanalizacao(){
    this.showAndHideTerms.sahTrombose = false;
    this.showAndHideTerms.sahRecanalizacao = true;
    this.showAndHideTerms.sahTromboseCronica = false;
    this.showAndHideTerms.sahRefluxo = false;
    this.showAndHideTerms.sahAusenciaFasicidade = false;
    this.showAndHideTerms.sahSegmentosNormais = false;
    this.catchIDs();
  }

  clickRecanalizacao2(){
    this.showAndHideTerms2.sahTrombose2 = false;
    this.showAndHideTerms2.sahRecanalizacao2 = true;
    this.showAndHideTerms2.sahTromboseCronica2 = false;
    this.showAndHideTerms2.sahRefluxo2 = false;
    this.showAndHideTerms2.sahAusenciaFasicidade2 = false;
    this.showAndHideTerms2.sahSegmentosNormais2 = false;
    this.catchIDs();
  }

  clickTromboseCronica(){
    this.showAndHideTerms.sahTrombose = false;
    this.showAndHideTerms.sahRecanalizacao = false;
    this.showAndHideTerms.sahTromboseCronica = true;
    this.showAndHideTerms.sahRefluxo = false;
    this.showAndHideTerms.sahAusenciaFasicidade = false;
    this.showAndHideTerms.sahSegmentosNormais = false;
    this.catchIDs();
  }

  clickTromboseCronica2(){
    this.showAndHideTerms2.sahTrombose2 = false;
    this.showAndHideTerms2.sahRecanalizacao2 = false;
    this.showAndHideTerms2.sahTromboseCronica2 = true;
    this.showAndHideTerms2.sahRefluxo2 = false;
    this.showAndHideTerms2.sahAusenciaFasicidade2 = false;
    this.showAndHideTerms2.sahSegmentosNormais2 = false;
    this.catchIDs();
  }

  clickRefluxo(){
    this.showAndHideTerms.sahTrombose = false;
    this.showAndHideTerms.sahRecanalizacao = false;
    this.showAndHideTerms.sahTromboseCronica = false;
    this.showAndHideTerms.sahRefluxo = true;
    this.showAndHideTerms.sahAusenciaFasicidade = false;
    this.showAndHideTerms.sahSegmentosNormais = false;
    this.catchIDs();
  }

  clickRefluxo2(){
    this.showAndHideTerms2.sahTrombose2 = false;
    this.showAndHideTerms2.sahRecanalizacao2 = false;
    this.showAndHideTerms2.sahTromboseCronica2 = false;
    this.showAndHideTerms2.sahRefluxo2 = true;
    this.showAndHideTerms2.sahAusenciaFasicidade2 = false;
    this.showAndHideTerms2.sahSegmentosNormais2 = false;
    this.catchIDs();
  }

  clickAusenciaFasicidade(){
    this.showAndHideTerms.sahTrombose = false;
    this.showAndHideTerms.sahRecanalizacao = false;
    this.showAndHideTerms.sahTromboseCronica = false;
    this.showAndHideTerms.sahRefluxo = false;
    this.showAndHideTerms.sahAusenciaFasicidade = true;
    this.showAndHideTerms.sahSegmentosNormais = false;
    this.catchIDs();
  }

  clickAusenciaFasicidade2(){
    this.showAndHideTerms2.sahTrombose2 = false;
    this.showAndHideTerms2.sahRecanalizacao2 = false;
    this.showAndHideTerms2.sahTromboseCronica2 = false;
    this.showAndHideTerms2.sahRefluxo2 = false;
    this.showAndHideTerms2.sahAusenciaFasicidade2 = true;
    this.showAndHideTerms2.sahSegmentosNormais2 = false;
    this.catchIDs();
  }

  clickSegmentosNormais(){
    this.showAndHideTerms.sahTrombose = false;
    this.showAndHideTerms.sahRecanalizacao = false;
    this.showAndHideTerms.sahTromboseCronica = false;
    this.showAndHideTerms.sahRefluxo = false;
    this.showAndHideTerms.sahAusenciaFasicidade = false;
    this.showAndHideTerms.sahSegmentosNormais = true;
    this.catchIDs();
  }
  
  clickSegmentosNormais2(){
    this.showAndHideTerms2.sahTrombose2 = false;
    this.showAndHideTerms2.sahRecanalizacao2 = false;
    this.showAndHideTerms2.sahTromboseCronica2 = false;
    this.showAndHideTerms2.sahRefluxo2 = false;
    this.showAndHideTerms2.sahAusenciaFasicidade2 = false;
    this.showAndHideTerms2.sahSegmentosNormais2 = true;
    this.catchIDs();
  }

  clickCheck(){
    this.catchIDs();
  }
  
  verificaSelecionados() {
    const isFemoralSuperficialChecked = this.ckFemoralSuperficial && this.ckFemoralSuperficial.checked ? true : false;
    const isTibialAnteriorChecked = this.ckTibialAnterior && this.ckTibialAnterior.checked ? true : false;
    const isTibialPosteriorChecked = this.ckTibialPosterior && this.ckTibialPosterior.checked ? true : false;
    const isFibularChecked = this.ckFibular && this.ckFibular.checked ? true : false;
  
    this.showAndHideTerms.sahListaSegmentos = isFemoralSuperficialChecked || isTibialAnteriorChecked || isTibialPosteriorChecked || isFibularChecked;
  }

  verificaSelecionados2(){
    const isFemoralSuperficialChecked2 = this.ckFemoralSuperficial2 && this.ckFemoralSuperficial2.checked ? true : false;
    const isTibialAnteriorChecked2 = this.ckTibialAnterior2 && this.ckTibialAnterior2.checked ? true : false;
    const isTibialPosteriorChecked2 = this.ckTibialPosterior2 && this.ckTibialPosterior2.checked ? true : false;
    const isFibularChecked2 = this.ckFibular2 && this.ckFibular2.checked ? true : false;

    this.showAndHideTerms2.sahListaSegmentos2 = isFemoralSuperficialChecked2 || isTibialAnteriorChecked2 || isTibialPosteriorChecked2 || isFibularChecked2;
  }

  catchIDs() {
    
    
    this.termoSelecionado.emit('Clean');
    debugger
    if(this.lateralidadeSelected === 'direito'){
      this.verificaSelecionados();
      let countVaso = 0;
      let countSegmento = 0;
      let countAchados = 0;
      if(this.rdNaoCitar && this.rdNaoCitar.checked){
        this.termoSelecionado.emit(this.rdNaoCitar.id);
      } 
      if(this.rdTrombose && this.rdTrombose.checked){
        this.termoSelecionado.emit(this.rdTrombose.id);
        if(this.rdHiperecogenico.checked){
          let objetoElemento = {id: "5711", value: 'hiperecogenico'};
          const objetoCriado = { elemento: objetoElemento};
          this.termosGenericos.emit(objetoCriado);
        }
        if(this.rdHipoecogenico.checked){
          let objetoElemento = {id: "5711", value: 'hipoecogenico'};
          const objetoCriado = { elemento: objetoElemento};
          this.termosGenericos.emit(objetoCriado);
        }
        this.verificaVaso(countVaso);
        this.verificaSegmento(countSegmento);
      }
      if(this.rdRecanalizacao && this.rdRecanalizacao.checked){
        this.termoSelecionado.emit(this.rdRecanalizacao.id);
        this.verificaVaso(countVaso);
        this.verificaSegmento(countSegmento);
      }
      if(this.rdTromboseCronica && this.rdTromboseCronica.checked){
        this.termoSelecionado.emit(this.rdTromboseCronica.id);
        this.verificaAchados(countAchados);
        this.verificaVaso(countVaso);
        this.verificaSegmento(countSegmento);
      }
      if(this.rdRefluxo && this.rdRefluxo.checked){
        this.termoSelecionado.emit(this.rdRefluxo.id);
        this.verificaVaso(countVaso);
        this.verificaSegmento(countSegmento);
      }
      if(this.rdAusenciaFasicidade && this.rdAusenciaFasicidade.checked){
        this.termoSelecionado.emit(this.rdAusenciaFasicidade.id);
        this.verificaVaso(countVaso);
        this.verificaSegmento(countSegmento);
      }
      if(this.rdSegmentosNormais && this.rdSegmentosNormais.checked){
        this.termoSelecionado.emit(this.rdSegmentosNormais.id);
      }
    } else if (this.lateralidadeSelected === 'esquerdo'){
      this.verificaSelecionados2();
      let countVaso = 0;
      let countSegmento = 0;
      let countAchados = 0;
      if(this.rdNaoCitar2 && this.rdNaoCitar2.checked){
        this.termoSelecionado.emit(this.rdNaoCitar2.id);
      } 
      if(this.rdTrombose2 && this.rdTrombose2.checked){
        this.termoSelecionado.emit(this.rdTrombose2.id);
        if(this.rdHiperecogenico2.checked){
          let objetoElemento = {id: "5711", value: 'hiperecogenico'};
          const objetoCriado = { elemento: objetoElemento};
          this.termosGenericos.emit(objetoCriado);
        }
        if(this.rdHipoecogenico2.checked){
          let objetoElemento = {id: "5711", value: 'hipoecogenico'};
          const objetoCriado = { elemento: objetoElemento};
          this.termosGenericos.emit(objetoCriado);
        }
        this.verificaVaso2(countVaso);
        this.verificaSegmento2(countSegmento);
      }
      if(this.rdRecanalizacao2 && this.rdRecanalizacao2.checked){
        this.termoSelecionado.emit(this.rdRecanalizacao2.id);
        this.verificaVaso2(countVaso);
        this.verificaSegmento2(countSegmento);
      }
      if(this.rdTromboseCronica2 && this.rdTromboseCronica2.checked){
        this.termoSelecionado.emit(this.rdTromboseCronica2.id);
        this.verificaAchados2(countAchados);
        this.verificaVaso2(countVaso);
        this.verificaSegmento2(countSegmento);
      }
      if(this.rdRefluxo2 && this.rdRefluxo2.checked){
        this.termoSelecionado.emit(this.rdRefluxo2.id);
        this.verificaVaso2(countVaso);
        this.verificaSegmento2(countSegmento);
      }
      if(this.rdAusenciaFasicidade2 && this.rdAusenciaFasicidade2.checked){
        this.termoSelecionado.emit(this.rdAusenciaFasicidade2.id);
        this.verificaVaso2(countVaso);
        this.verificaSegmento2(countSegmento);
      }
      if(this.rdSegmentosNormais2 && this.rdSegmentosNormais2.checked){
        this.termoSelecionado.emit(this.rdSegmentosNormais2.id);
      }
    }

    this.listaCompleta.emit();
  }

  verificaVaso(countVaso){
    let vaso = '';

    if(this.ckFemoralComum != undefined && this.ckFemoralComum.checked){
      if(vaso != '' && countVaso < 2){
        vaso = vaso + ' e ' + this.ckFemoralComum.value;
        countVaso++;
      } else if(countVaso >=2){
        vaso = vaso.replace(' e ', ', ');
        vaso = vaso + ' e ' + this.ckFemoralComum.value;
        countVaso++;
      } else{
        vaso = this.ckFemoralComum.value;
        countVaso++;
      }
    }

    if(this.ckFemoralProfunda != undefined && this.ckFemoralProfunda.checked){
      if(vaso != '' && countVaso < 2){
        vaso = vaso + ' e ' + this.ckFemoralProfunda.value;
        countVaso++;
      } else if(countVaso >=2){
        vaso = vaso.replace(' e ', ', ');
        vaso = vaso + ' e ' + this.ckFemoralProfunda.value;
        countVaso++;
      } else{
        vaso = this.ckFemoralProfunda.value;
        countVaso++;
      }
    }

    if(this.ckFemoralSuperficial != undefined && this.ckFemoralSuperficial.checked){
      if(vaso != '' && countVaso < 2){
        vaso = vaso + ' e ' + this.ckFemoralSuperficial.value;
        countVaso++;
      } else if(countVaso >=2){
        vaso = vaso.replace(' e ', ', ');
        vaso = vaso + ' e ' + this.ckFemoralSuperficial.value;
        countVaso++;
      } else{
        vaso = this.ckFemoralSuperficial.value;
        countVaso++;
      }
    }

    if(this.ckPoplitea != undefined && this.ckPoplitea.checked){
      if(vaso != '' && countVaso < 2){
        vaso = vaso + ' e ' + this.ckPoplitea.value;
        countVaso++;
      } else if(countVaso >=2){
        vaso = vaso.replace(' e ', ', ');
        vaso = vaso + ' e ' + this.ckPoplitea.value;
        countVaso++;
      } else{
        vaso = this.ckPoplitea.value;
        countVaso++;
      }
    }

    if(this.ckTibialAnterior != undefined && this.ckTibialAnterior.checked){
      if(vaso != '' && countVaso < 2){
        vaso = vaso + ' e ' + this.ckTibialAnterior.value;
        countVaso++;
      } else if(countVaso >=2){
        vaso = vaso.replace(' e ', ', ');
        vaso = vaso + ' e ' + this.ckTibialAnterior.value;
        countVaso++;
      } else{
        vaso = this.ckTibialAnterior.value;
        countVaso++;
      }
    }

    if(this.ckTibialPosterior != undefined && this.ckTibialPosterior.checked){
      if(vaso != '' && countVaso < 2){
        vaso = vaso + ' e ' + this.ckTibialPosterior.value;
        countVaso++;
      } else if(countVaso >=2){
        vaso = vaso.replace(' e ', ', ');
        vaso = vaso + ' e ' + this.ckTibialPosterior.value;
        countVaso++;
      } else{
        vaso = this.ckTibialPosterior.value;
        countVaso++;
      }
    }

    if(this.ckFibular != undefined && this.ckFibular.checked){
      if(vaso != '' && countVaso < 2){
        vaso = vaso + ' e ' + this.ckFibular.value;
        countVaso++;
      } else if(countVaso >=2){
        vaso = vaso.replace(' e ', ', ');
        vaso = vaso + ' e ' + this.ckFibular.value;
        countVaso++;
      } else{
        vaso = this.ckFibular.value;
        countVaso++;
      }
    }

    if(this.ckSoleares != undefined && this.ckSoleares.checked){
      if(vaso != '' && countVaso < 2){
        vaso = vaso + ' e ' + this.ckSoleares.value;
        countVaso++;
      } else if(countVaso >=2){
        vaso = vaso.replace(' e ', ', ');
        vaso = vaso + ' e ' + this.ckSoleares.value;
        countVaso++;
      } else{
        vaso = this.ckSoleares.value;
        countVaso++;
      }
    }

    if(this.ckIntergemelar != undefined && this.ckIntergemelar.checked){
      if(vaso != '' && countVaso < 2){
        vaso = vaso + ' e ' + this.ckIntergemelar.value;
        countVaso++;
      } else if(countVaso >=2){
        vaso = vaso.replace(' e ', ', ');
        vaso = vaso + ' e ' + this.ckIntergemelar.value;
        countVaso++;
      } else{
        vaso = this.ckIntergemelar.value;
        countVaso++;
      }
    }

    if(vaso != ''){
      const objetoElemento = {id: 5708, value: vaso};
      this.objetoVaso = {elemento: objetoElemento, isPrimeiraLesao: true};
      this.termosGenericos.emit(this.objetoVaso);
    }
  }

  verificaVaso2(countVaso){
    let vaso = '';

    if(this.ckFemoralComum2 != undefined && this.ckFemoralComum2.checked){
      if(vaso != '' && countVaso < 2){
        vaso = vaso + ' e ' + this.ckFemoralComum2.value;
        countVaso++;
      } else if(countVaso >=2){
        vaso = vaso.replace(' e ', ', ');
        vaso = vaso + ' e ' + this.ckFemoralComum2.value;
        countVaso++;
      } else{
        vaso = this.ckFemoralComum2.value;
        countVaso++;
      }
    }

    if(this.ckFemoralProfunda2 != undefined && this.ckFemoralProfunda2.checked){
      if(vaso != '' && countVaso < 2){
        vaso = vaso + ' e ' + this.ckFemoralProfunda2.value;
        countVaso++;
      } else if(countVaso >=2){
        vaso = vaso.replace(' e ', ', ');
        vaso = vaso + ' e ' + this.ckFemoralProfunda2.value;
        countVaso++;
      } else{
        vaso = this.ckFemoralProfunda2.value;
        countVaso++;
      }
    }

    if(this.ckFemoralSuperficial2 != undefined && this.ckFemoralSuperficial2.checked){
      if(vaso != '' && countVaso < 2){
        vaso = vaso + ' e ' + this.ckFemoralSuperficial2.value;
        countVaso++;
      } else if(countVaso >=2){
        vaso = vaso.replace(' e ', ', ');
        vaso = vaso + ' e ' + this.ckFemoralSuperficial2.value;
        countVaso++;
      } else{
        vaso = this.ckFemoralSuperficial2.value;
        countVaso++;
      }
    }

    if(this.ckPoplitea2 != undefined && this.ckPoplitea2.checked){
      if(vaso != '' && countVaso < 2){
        vaso = vaso + ' e ' + this.ckPoplitea2.value;
        countVaso++;
      } else if(countVaso >=2){
        vaso = vaso.replace(' e ', ', ');
        vaso = vaso + ' e ' + this.ckPoplitea2.value;
        countVaso++;
      }
      else{
        vaso = this.ckPoplitea2.value;
        countVaso++;
      }
    }

    if(this.ckTibialAnterior2 != undefined && this.ckTibialAnterior2.checked){
      if(vaso != '' && countVaso < 2){
        vaso = vaso + ' e ' + this.ckTibialAnterior2.value;
        countVaso++;
      } else if(countVaso >=2){
        vaso = vaso.replace(' e ', ', ');
        vaso = vaso + ' e ' + this.ckTibialAnterior2.value;
        countVaso++;
      } else{
        vaso = this.ckTibialAnterior2.value;
        countVaso++;
      }
    }

    if(this.ckTibialPosterior2 != undefined && this.ckTibialPosterior2.checked){
      if(vaso != '' && countVaso < 2){
        vaso = vaso + ' e ' + this.ckTibialPosterior2.value;
        countVaso++;
      } else if(countVaso >=2){
        vaso = vaso.replace(' e ', ', ');
        vaso = vaso + ' e ' + this.ckTibialPosterior2.value;
        countVaso++;
      } else{
        vaso = this.ckTibialPosterior2.value;
        countVaso++;
      }
    }

    if(this.ckFibular2 != undefined && this.ckFibular2.checked){
      if(vaso != '' && countVaso < 2){
        vaso = vaso + ' e ' + this.ckFibular2.value;
        countVaso++;
      } else if(countVaso >=2){
        vaso = vaso.replace(' e ', ', ');
        vaso = vaso + ' e ' + this.ckFibular2.value;
        countVaso++;
      } else{
        vaso = this.ckFibular2.value;
        countVaso++;
      }
    }

    if(this.ckSoleares2 != undefined && this.ckSoleares2.checked){
      if(vaso != '' && countVaso < 2){
        vaso = vaso + ' e ' + this.ckSoleares2.value;
        countVaso++;
      } else if(countVaso >=2){
        vaso = vaso.replace(' e ', ', ');
        vaso = vaso + ' e ' + this.ckSoleares2.value;
        countVaso++;
      } else{
        vaso = this.ckSoleares2.value;
        countVaso++;
      }
    }

    if(this.ckIntergemelar2 != undefined && this.ckIntergemelar2.checked){
      if(vaso != '' && countVaso < 2){
        vaso = vaso + ' e ' + this.ckIntergemelar2.value;
        countVaso++;
      } else if(countVaso >=2){
        vaso = vaso.replace(' e ', ', ');
        vaso = vaso + ' e ' + this.ckIntergemelar2.value;
        countVaso++;
      } else{
        vaso = this.ckIntergemelar2.value;
        countVaso++;
      }
    }

    if(vaso != ''){
      const objetoElemento = {id: 5708, value: vaso};
      this.objetoVaso2 = {elemento: objetoElemento, isPrimeiraLesao: true};
      this.termosGenericos.emit(this.objetoVaso2);
    }

  }
  verificaSegmento(countSegmento){
    let segmento = '';

    if(this.ckTercoSuperior != undefined && this.ckTercoSuperior.checked){
      if(segmento != '' && countSegmento < 2){
        segmento = segmento + ' e ' + this.ckTercoSuperior.value;
        countSegmento++;
      } else if(countSegmento >=2){
        segmento = segmento.replace(' e ', ', ');
        segmento = segmento + ' e ' + this.ckTercoSuperior.value;
        countSegmento++;
      } else{
        segmento = this.ckTercoSuperior.value;
        countSegmento++;
      }
    }

    if(this.ckTercoMedio != undefined && this.ckTercoMedio.checked){
      if(segmento != '' && countSegmento < 2){
        segmento = segmento + ' e ' + this.ckTercoMedio.value;
        countSegmento++;
      } else if(countSegmento >=2){
        segmento = segmento.replace(' e ', ', ');
        segmento = segmento + ' e ' + this.ckTercoMedio.value;
        countSegmento++;
      } else{
        segmento = this.ckTercoMedio.value;
        countSegmento++;
      }
    }

    if(this.ckTercoDistal != undefined && this.ckTercoDistal.checked){
      if(segmento != '' && countSegmento < 2){
        segmento = segmento + ' e ' + this.ckTercoDistal.value;
        countSegmento++;
      } else if(countSegmento >=2){
        segmento = segmento.replace(' e ', ', ');
        segmento = segmento + ' e ' + this.ckTercoDistal.value;
        countSegmento++;
      } else{
        segmento = this.ckTercoDistal.value;
        countSegmento++;
      }
    }

    if(segmento != ''){
      const objetoElemento = {id: 5709, value: segmento};
      this.objetoSegmento = {elemento: objetoElemento, isPrimeiraLesao: true};
      this.termosGenericos.emit(this.objetoSegmento);
    }
  }
  verificaSegmento2(countSegmento){
    let segmento = '';

    if(this.ckTercoSuperior2 != undefined && this.ckTercoSuperior2.checked){
      if(segmento != '' && countSegmento < 2){
        segmento = segmento + ' e ' + this.ckTercoSuperior2.value;
        countSegmento++;
      } else if(countSegmento >=2){
        segmento = segmento.replace(' e ', ', ');
        segmento = segmento + ' e ' + this.ckTercoSuperior2.value;
        countSegmento++;
      } else{
        segmento = this.ckTercoSuperior2.value;
        countSegmento++;
      }
    }

    if(this.ckTercoMedio2 != undefined && this.ckTercoMedio2.checked){
      if(segmento != '' && countSegmento < 2){
        segmento = segmento + ' e ' + this.ckTercoMedio2.value;
        countSegmento++;
      } else if(countSegmento >=2){
        segmento = segmento.replace(' e ', ', ');
        segmento = segmento + ' e ' + this.ckTercoMedio2.value;
        countSegmento++;
      } else{
        segmento = this.ckTercoMedio2.value;
        countSegmento++;
      }
    }

    if(this.ckTercoDistal2 != undefined && this.ckTercoDistal2.checked){
      if(segmento != '' && countSegmento < 2){
        segmento = segmento + ' e ' + this.ckTercoDistal2.value;
        countSegmento++;
      } else if(countSegmento >=2){
        segmento = segmento.replace(' e ', ', ');
        segmento = segmento + ' e ' + this.ckTercoDistal2.value;
        countSegmento++;
      } else{
        segmento = this.ckTercoDistal2.value;
        countSegmento++;
      }
    }

    if(segmento != ''){
      const objetoElemento = {id: 5709, value: segmento};
      this.objetoSegmento2 = {elemento: objetoElemento, isPrimeiraLesao: true};
      this.termosGenericos.emit(this.objetoSegmento2);
    }
  }
  verificaAchados(countAchados){
    let achados = '';

    if(this.ckEspessamentoParietal != undefined && this.ckEspessamentoParietal.checked){
      if(achados != '' && countAchados < 2){
        achados = achados + ' e ' + this.ckEspessamentoParietal.value;
        countAchados++;
      } else if(countAchados >=2){
        achados = achados.replace(' e ', ', ');
        achados = achados + ' e ' + this.ckEspessamentoParietal.value;
        countAchados++;
      } else{
        achados = this.ckEspessamentoParietal.value;
        countAchados++;
      }
    }

    if(this.ckTrabeculacoes != undefined && this.ckTrabeculacoes.checked){
      if(achados != '' && countAchados < 2){
        achados = achados + ' e ' + this.ckTrabeculacoes.value;
        countAchados++;
      } else if(countAchados >=2){
        achados = achados.replace(' e ', ', ');
        achados = achados + ' e ' + this.ckTrabeculacoes.value;
        countAchados++;
      } else{
        achados = this.ckTrabeculacoes.value;
        countAchados++;
      }
    }

    if(this.ckRefluxo != undefined && this.ckRefluxo.checked){
      if(achados != '' && countAchados < 2){
        achados = achados + ' e ' + this.ckRefluxo.value;
        countAchados++;
      } else if(countAchados >=2){
        achados = achados.replace(' e ', ', ');
        achados = achados + ' e ' + this.ckRefluxo.value;
        countAchados++;
      } else{
        achados = this.ckRefluxo.value;
        countAchados++;
      }
    }

    if(this.ckPerdaRelacaoAnatomica != undefined && this.ckPerdaRelacaoAnatomica.checked){
      if(achados != '' && countAchados < 2){
        achados = achados + ' e ' + this.ckPerdaRelacaoAnatomica.value;
        countAchados++;
      } else if(countAchados >=2){
        achados = achados.replace(' e ', ', ');
        achados = achados + ' e ' + this.ckPerdaRelacaoAnatomica.value;
        countAchados++;
      }
      else{
        achados = this.ckPerdaRelacaoAnatomica.value;
        countAchados++;
      }
    }
    
    if(achados != ''){
      const objetoElemento = {id: 5710, value: achados};
      this.objetoAchados = {elemento: objetoElemento, isPrimeiraLesao: true};
      this.termosGenericos.emit(this.objetoAchados);
    }
  }
  verificaAchados2(countAchados){
    let achados = '';

    if(this.ckEspessamentoParietal2 != undefined && this.ckEspessamentoParietal2.checked){
      if(achados != '' && countAchados < 2){
        achados = achados + ' e ' + this.ckEspessamentoParietal2.value;
        countAchados++;
      } else if(countAchados >=2){
        achados = achados.replace(' e ', ', ');
        achados = achados + ' e ' + this.ckEspessamentoParietal2.value;
        countAchados++;
      } else{
        achados = this.ckEspessamentoParietal2.value;
        countAchados++;
      }
    }

    if(this.ckTrabeculacoes2 != undefined && this.ckTrabeculacoes2.checked){
      if(achados != '' && countAchados < 2){
        achados = achados + ' e ' + this.ckTrabeculacoes2.value;
        countAchados++;
      } else if(countAchados >=2){
        achados = achados.replace(' e ', ', ');
        achados = achados + ' e ' + this.ckTrabeculacoes2.value;
        countAchados++;
      } else{
        achados = this.ckTrabeculacoes2.value;
        countAchados++;
      }
    }

    if(this.ckRefluxo2 != undefined && this.ckRefluxo2.checked){
      if(achados != '' && countAchados < 2){
        achados = achados + ' e ' + this.ckRefluxo2.value;
        countAchados++;
      } else if(countAchados >=2){
        achados = achados.replace(' e ', ', ');
        achados = achados + ' e ' + this.ckRefluxo2.value;
        countAchados++;
      } else{
        achados = this.ckRefluxo2.value;
        countAchados++;
      }
    }

    if(this.ckPerdaRelacaoAnatomica2 != undefined && this.ckPerdaRelacaoAnatomica2.checked){
      if(achados != '' && countAchados < 2){
        achados = achados + ' e ' + this.ckPerdaRelacaoAnatomica2.value;
        countAchados++;
      } else if(countAchados >=2){
        achados = achados.replace(' e ', ', ');
        achados = achados + ' e ' + this.ckPerdaRelacaoAnatomica2.value;
        countAchados++;
      } else{
        achados = this.ckPerdaRelacaoAnatomica2.value;
        countAchados++;
      }
    }

    if(achados != ''){
      const objetoElemento = {id: 5710, value: achados};
      this.objetoAchados2 = {elemento: objetoElemento, isPrimeiraLesao: true};
      this.termosGenericos.emit(this.objetoAchados2);
    }
  }
}
