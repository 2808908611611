import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-genitalia-gemelar',
  templateUrl: './genitalia-gemelar.component.html',
  styleUrls: ['./genitalia-gemelar.component.css']
})
export class GenitaliaGemelarComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() enviaAlterado = new EventEmitter();

  @ViewChild('slGenitalia') slGenitalia: MatSelect;
  @ViewChild('slGenitalia2') slGenitalia2: MatSelect;
  @ViewChild('slGenitalia3') slGenitalia3: MatSelect;
  @ViewChild('slGenitalia4') slGenitalia4: MatSelect;

  genitalias: ArrayDropDown[] = [
    { value: 'Feminino', viewValue: 'Feminino' },
    { value: 'Masculino', viewValue: 'Masculino' },
    { value: 'Não foi possível avaliar devido a dificuldade técnica', viewValue: 'Não identificada' },
    { value: 'Identificada e não descrita à pedido dos pais', viewValue: 'Não descrita à pedido' },
  ];

  fetoSelected: number = 1;
  showAndHideFetos: any;

  constructor( private _common: CommonService) { }

  ngOnInit() {
    this._common.posicaoFeto.subscribe((feto: any) => {
      this.fetoSelected = feto;
      this.showAndHideFetos = {
        feto1: feto === 1,
        feto2: feto === 2,
        feto3: feto === 3,
        feto4: feto === 4,
      };
    });

    this.showAndHideFetos = {
      feto1: true,
      feto2: false,
      feto3: false,
      feto4: false,
    };
  }

  changeSelect() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');
    if(this.fetoSelected === 1) {
      if (this.slGenitalia && this.slGenitalia.value) {
        const objeto = { elemento: this.slGenitalia, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    } else if(this.fetoSelected === 2) {
      if (this.slGenitalia2 && this.slGenitalia2.value) {
        const objeto = { elemento: this.slGenitalia2, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    } else if(this.fetoSelected === 3) {
      if (this.slGenitalia3 && this.slGenitalia3.value) {
        const objeto = { elemento: this.slGenitalia3, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    } else if(this.fetoSelected === 4) {
      if (this.slGenitalia4 && this.slGenitalia4.value) {
        const objeto = { elemento: this.slGenitalia4, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
    this.listaCompleta.emit();
  }

}
